// strona koszyka

import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { reduxActions, useDispatch } from 'store';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { ChevronLeft } from 'react-bootstrap-icons';
import debounce from 'lodash/debounce';

import { useAppNavigate, useNotifications } from 'hooks';
import {
  useGetCart,
  useGetCartsAll,
  useGetCartPaymentsExpired,
  useGetCartPositions,
  usePostCartClear,
  usePostCartValidate
} from 'api';
import { ICartPositionListItem } from 'api/types';
import { Alert, Breadcrumbs, Container, Link, Loader } from 'components/controls';
import { CartPositions, CartSummary } from 'components/containers';

import styles from 'theme/pages/Cart/Cart.module.scss';
import { CreditCard } from 'assets/icons';

const Cart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useAppNavigate();
  const { showErrorMessage } = useNotifications();

  // ID aktualnego koszyka z url'a
  const { id } = useParams();
  // przekształcenie w numer
  const cartId = useMemo(() => parseInt(id || ''), [id]);

  // fraza wyszukiwania
  const [searchQuery, setSearchQuery] = useState('');

  // lista pozycji do usunięcia
  const [itemsToRemove, setItemsToRemove] = useState<ICartPositionListItem[]>([]);

  // lista zaznaczonyxh pozycji (ID'ki)
  const [checkedItemIds, setCheckedItemIds] = useState<number[]>([]);

  // parametry zapytania API
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    searchKeyword: '',
    sort_method: ''
  });

  // pobranie informacji o przedawnionych płatności - potrzebne do wyświetlenia alertu
  const { data: paymentsExpiredData } = useGetCartPaymentsExpired(cartId);

  // pobranie listy koszyków
  const {
    data: cartsData,
    refetch: refetchCartsData,
    isLoading: isCartLoading
  } = useGetCartsAll({
    enabled: false,
    onSuccess: (data) => {
      const currentCartItem = data.items.find((item) => item.id === cartId);
      const firstCartId = data?.items[0]?.id;
      const secondCartId = data?.items[0]?.id;
      currentCartItem
        ? refetchCartData()
        : navigate(
            firstCartId && firstCartId !== cartId
              ? `/cart/${firstCartId}`
              : secondCartId && secondCartId !== cartId
              ? `/cart/${secondCartId}`
              : '/'
          );
    }
  });

  // pobranie listy pozycji koszyka
  const {
    data: cartPositionsData,
    refetch: refetchCartPositions,
    isLoading: isCartPositionsLoading,
    isRefetching: isCartPositionsRefetching
  } = useGetCartPositions(cartId, queryParams, {
    enabled: false,
    keepPreviousData: true
  });

  // odświeżanie szczegółów koszyka
  const { refetch: refetchCartData } = useGetCart(cartId, {
    enabled: false
  });

  // czyszczenie koszyka
  const { mutate: clearCartPositions } = usePostCartClear(cartId, {
    onSuccess: (response) => {
      navigate(`/cart/${response.data.id}`);
      refetchCartsData();
    }
  });

  // walidacja koszyka
  const { mutate: validateCart, isLoading: isPostCartValidating } = usePostCartValidate(
    cartId || 0,
    {
      onSuccess: () => {
        navigate(`/checkout/${cartId}?checkoutStep=BILLING`);
      },
      onError: (data) => {
        if (!data.status) {
          refetchCartsData();
          refetchCartData();
          refetchCartPositions();
        }
      }
    }
  );

  // pobieranie początkowych pozycji koszyka
  useEffect(() => {
    if (cartId) {
      refetchCartPositions();
    }
  }, [cartId, queryParams.sort_method, queryParams.page]);

  // Ustawienie breadcrumbs'ów (przy renderowaniu strony)
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs([
        {
          name: t('Twój koszyk'),
          path: undefined
        }
      ])
    );
  }, []);

  const currentCart = useMemo(
    () => cartsData?.items.find((item) => item.id === cartId),
    [cartsData, cartId]
  );

  // funcja aktualizująca frazę wyszukiwania (sekunda opóźnienia dla optymalizacji ilości zapytań do api)
  const searchQueryDebounce = useCallback(
    debounce(() => refetchCartPositions(), 500),
    []
  );

  // nasłuchiwanie na zmiany frazy wyszukiwania w lokalnym inpucie
  useEffect(() => {
    if (searchQuery) {
      searchQueryDebounce();
    }
  }, [searchQuery]);

  const clearCart = () => {
    if (cartPositionsData) {
      clearCartPositions();
    }
  };

  const handleButtonClick = () => {
    if ((currentCart?.products_count || 0) === 0) {
      showErrorMessage(t('Koszyk jest pusty'));
      return;
    }

    validateCart({ verification_scope: 'positions|cartsPricesUpdate' });
  };

  return (
    <div className={classnames(styles.componentWrapper, 'StylePath-Pages-Cart')}>
      <Container>
        <div className={styles.header}>
          <Breadcrumbs />
          <div className={styles.paymentsExpiredData}>
            {paymentsExpiredData?.message && paymentsExpiredData.expired_payments_count > 0 && (
              <Alert type="error" icon={<CreditCard />}>
                <span>{paymentsExpiredData.message}</span>
                <Link to="/dashboard/documents" className={styles.link}>
                  <Trans>Przejdź do zakładki</Trans> <ChevronLeft className={styles.nextIcon} />
                </Link>
              </Alert>
            )}
          </div>
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            {isCartLoading || isCartPositionsLoading ? (
              <Loader />
            ) : (
              <CartPositions
                cartId={cartId}
                isCart={true}
                itemsToRemove={itemsToRemove}
                setItemsToRemove={setItemsToRemove}
                checkedItemIds={checkedItemIds}
                setCheckedItemIds={setCheckedItemIds}
                cartPositionsData={cartPositionsData}
                refetchCartPositions={refetchCartPositions}
                isCartPositionsRefetching={isCartPositionsRefetching}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                onChange={() => {
                  refetchCartsData();
                }}
                searchKeyword={searchQuery}
                noDataPlaceholder={
                  <div className={styles.notFound}>
                    <h3>
                      <Trans>Brak wyników</Trans>
                    </h3>
                    {searchQuery && (
                      <span>
                        <Trans>Zmień kryteria wyszukiwania</Trans>
                      </span>
                    )}
                  </div>
                }
              />
            )}
          </div>
          {!!cartsData?.items[0].products_count && (
            <div className={styles.cartSummary}>
              <CartSummary
                cartId={cartId}
                buttonOnClick={handleButtonClick}
                clearCart={clearCart}
                isCart
                isLoading={isPostCartValidating}
              />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Cart;
